// eslint-disable-next-line import/no-named-as-default
import Sortable from 'sortablejs'
import { csrfToken } from '@/src/lib/rails'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector<HTMLElement>('.js-sortable')
  if (!element) return

  new Sortable(element, {
    handle: '.js-sortable-handle',
    onUpdate: ({ item, newIndex }): void => {
      const data = {
        bookmark: {
          row_order_position: newIndex,
        },
      }

      const token = csrfToken()
      const tokenHeader: Record<string, string> = token ? { 'X-CSRF-Token': token } : {}

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fetch(item.dataset.updateUrl!, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          ...tokenHeader,
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      })
    },
  })
})
