import '@/src/lib/requestIdleCallbackPolyfill'
import '@/src/lib/iconifyIcon'
import 'bootstrap'
import '@sonicgarden/autosize-textarea-element'
import '@sonicgarden/bs-tooltip-element'
import '@sonicgarden/bs-hovercard-element'
import '@sonicgarden/time-elements'
import '@sonicgarden/leave-check-form-element'
import { ImageCompression } from '@sonicgarden/image-compression-element'
import { start } from '@sonicgarden/rails-ujs-compat'
import { initLib } from '@/src/lib/initLib'
import { initVue } from '@/src/lib/vue'
import 'bootstrap-vue-next'

import '@/src/legacy_pages'
import '@/src/custom_elements/JsonTextarea'
import '@/src/custom_elements/PasskeyRegistration'
import '@/src/custom_elements/PasskeyLogin'

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

start()
initLib()
initVue()

ImageCompression.defaultOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 4096,
  useWebWorker: true,
  initialQuality: 0.9,
}
